.imageBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    margin: 1rem 0;

    @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }

    .image {
        width: 100%;

        @media (max-width: 980px) {
            width: 100vw;
        }
    }
}

.container {
    padding-top: 5.5rem;
}