.imageBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    margin: 1rem 0;

    @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }

    .image {
        width: 100%;

        @media (max-width: 980px) {
            width: 100vw;
        }
    }
}

.container {
    padding-top: 5.5rem;
}


.buttonBoxTitle {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 600;
    margin: 2.5rem 0 1.5rem 0;
}

.buttonBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 0 2rem 0;
}