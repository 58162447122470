.overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
}

.modalContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    height: 20rem;
    background-color: #FFFFFF;

    @media (max-width: 980px) {
        width: 80%;
        height: 45%;
    }

    .img {
        width: 30%;
        height: 100%;
    }

    .closeButton {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
}

.bookingContainer {
    margin: 1rem;
    width: 100%;
    height: 100%;
    font-family: "Noto Sans KR", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.2rem;

    .bookingForm {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        font-weight: 400;
        font-size: 1rem;

        div {
            margin-bottom: 1rem;

            @media (max-width: 980px) {
                margin-bottom: 0.5rem;
            }
        }

        .input {
            margin-bottom: 1rem;
            width: 97%;
            height: 2rem;
            font-style: italic;
            text-align: center;

            @media (max-width: 980px) {
                margin-bottom: 0.5rem;
            }
        }
    }
}

.disabledButton {
    margin: 1rem 0 0 0;
    width: 100%;
    height: 2.35rem;
    border-radius: 0.2rem;
    color: #FFFFFF;
    background-color: #284F4E;
    border-radius: 0.2rem;
    font-size: 0.8rem;
}

.button {
    margin: 1rem 0 0 0;
    width: 100%;
    height: 2.35rem;
    color: #FFFFFF;
    background-color: #284F4E;
    border-radius: 0.2rem;
    font-size: 0.8rem;
    font-weight: bold;
}