.webContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
}

.floor1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

    img {
        height: 20vw;
    }
}

.floor2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 0.2rem;

    img {
        width: 35vw;
    }
}

.floor2Right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    
    img {
        width: 35vw;
    }

    .menu{
        height: 12vw;
    }
}



.mobileContainer {
    width: 100vw;

    img {
        width: 100%;
    }
}

.mobileFloor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}