.header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 5.5rem;
  background-color: #284F4E;

  @media (max-width: 980px) {
    justify-content: space-between;
    height: 6rem;
    background: #284F4E;
  }

}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 3rem;
  color: #FFFFFF;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;


  .link {
    color: inherit;
    text-decoration: none;
  }

  .boldLink {
    color: inherit;
    text-decoration: none;
    font-weight: 600;
    border-bottom: 2px solid;
  }

  :hover {
    font-weight: 900;
    border-bottom: 1px solid;
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translate(0, -50%);
  width: 10rem;
}

.phoneNumber {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translate(0, -50%);
  width: 17rem;

  @media (max-width: 1280px) {
    width: 15rem;
  }

  @media (max-width: 1120px) {
    width: 10rem;
  }
}


.list {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;
  list-style: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 2.8rem;

  // 미디어 쿼리
  @media (max-width: 980px) {
    list-style-type: none;
    position: fixed;
    top: 0;
    right: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    width: 5rem;
    height: 100vh;
    background-color: #284F4E;
    padding: 2rem 2rem;
    color: var(--gray-900);
    z-index: 2;
  }

  .item {

    :hover {
      font-weight: bold;
    }

    // 미디어 쿼리
    @media (max-width: 980px) {
      font-size: 1rem;
      color: #FFFFFF;
      cursor: pointer;

      &.active {
        color: white;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.iconBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .callIcon {
    margin: 0 1rem 0 0;
    width: 1.5rem;
    height: 1.5rem;
  }

  .menuIcon {
    margin: 0 1rem 0 0;
    width: 2rem;
    height: 2rem;
  }
}

.closeButton {
  position: absolute;
  top: 2rem;
  right: 1rem;
  margin-top: 0.2rem;
  z-index: 3;
  cursor: pointer;
}

.redBox {
  position: absolute;
  top: 0.8rem;
  left: 12rem;
  padding: 0.5rem;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-size: 1rem;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  border-radius: 0.5rem;
  background-color: #ff0000;
  // border: 1px solid #ff0000;

  animation-name: twinkling;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @media (max-width: 1120px) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FFFFFF;
    color: #ff0000;
    border: 1px solid #ff0000;
  }

  @media (max-width: 980px) {
    top: -0.2rem;
    margin: 1.2rem;
  }

  @keyframes twinkling {
    0% {
      opacity: 0.5;
    }

    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.5;
    }
  }
}