.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	padding-top: 4rem;
	padding-bottom: 5rem;
	width: 100vw;
	background-color: #284F4E;

	@media (max-width: 980px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #284F4E;
	}
}

.text {
	margin-top: -1rem;
	font-size: 0.8rem;
	color: #FFFFFF;

	@media (max-width: 980px) {
		margin-top: -1rem;
		font-size: 0.5rem;
		text-align: center;
		background-color: #284F4E;
	}
}

.text2 {
	color: #FFFFFF;

	@media (max-width: 980px) {
		font-size: 1rem;
		font-weight: bold;
		text-align: center;
		background-color: #284F4E;
	}
}

.menu {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	gap: 3rem;
	text-align: center;
	color: #FFFFFF;

	.link {
		color: inherit;
		text-decoration: none;
	}

	:hover {
		font-weight: 900;
		border-bottom: 1px solid;
	}

	@media (max-width: 980px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		justify-content: center;
		align-items: center;
		gap: 1rem;
		background-color: #284F4E;
	}
}

.logo {
	margin: -1rem 0;
	width: 20rem;

	@media (max-width: 980px) {
		width: 10rem;
	}
}

.logoBox {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	margin: -1rem 0 -1rem 0;
}